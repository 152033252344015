<template>
  <div v-if="hasBanner" class="slider-box">
    <swiper :options="swiperOption" id="js-centered-slides">
      <swiper-slide v-for="(item, index) in banners" :key="index" :class="'slider-item'">
        <center-slider-item :banner="item" @click="selectPromotionHandler"></center-slider-item>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
import 'swiper/dist/css/swiper.css';
import { swiper, swiperSlide } from 'vue-awesome-swiper';
import centerSliderItem from '@/components/mobile/slider/center-slider-item.vue';
import { createPromotionItem, sendGA4sEvent } from '@/helpers/tracking/ga';

const swiperOption = {
  slidesPerView: 1.3, // 畫面只能 1.3 個 slider 的寬度
  centeredSlides: true, // 並且當前 slider 必須置中
  spaceBetween: 10,
  grabCursor: true,
  loop: true,
};

const data = function () {
  return {
    swiperOption,
  };
};

const computed = {
  hasBanner() {
    return this.banners.length > 0;
  },
  banners() {
    return this.$store.state.Cart.banners || [];
  },
};

const methods = {
  selectPromotionHandler(banner) {
    const parameterForGA = createPromotionItem(banner);
    sendGA4sEvent('select_promotion', parameterForGA);
  },
};

const components = {
  swiper,
  swiperSlide,
  centerSliderItem,
};

export default {
  data,
  computed,
  components,
  methods,
};
</script>

<template>
  <div v-if="isMobile">
    <header-cart>
      <template slot="header-left">
        <a class="btn-link" href="javascript:void(0);" @click="goBackFromHeader">
          <i class="icon icon-arrow-left"></i>
        </a>
      </template>
      <template slot="header-middle">購物車明細</template>
      <template slot="header-right" v-if="isLogin">
        <!--收藏按鈕-->
        <a v-if="isNotFromMuchMuch" :href="favoriteUrl" class="btn-link">
          <i class="icon icon-heart"></i>
        </a>
      </template>
    </header-cart>
    <main style="min-height: 100vh; padding-top: 40px">
      <div class="card text-center">
        <div class="spacing lg top">
          <i class="icon-cart-graylight icon icon-xxl icon-cicle-graylight"></i>
        </div>
        <p class="text-muted spacing xl top">購物車內沒有商品</p>
        <div class="btn-group sticky spacing top">
          <a :class="['btn btn-primary', { 'btn-round': isMobile }]" :href="setUrl"> 開始購物 </a>
        </div>
      </div>
      <center-slider v-if="isNotFromMuchMuch"></center-slider>
    </main>
  </div>
  <!--  PC 版-->
  <div class="wrapper" v-else>
    <div class="container">
      <main class="row">
        <div class="table-pack gray">
          <div class="table-pack-head">
            <i class="icon icon-nav-cart"></i>
            &nbsp;購物車明細
            <span class="sub">
              共
              <b class="value">0</b>
              項商品
            </span>
          </div>
          <div class="alert alert-default">
            <p class="lead text-center divider horizontal lg">您目前還沒有選購任何商品，前往選購第一件商品吧！</p>
            <div class="btn-group">
              <a class="btn btn-secondary" :href="online"> 開始購物 </a>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import { goBackFromHeader } from '@/helpers/navigation-goback-where';
import { setUrl, online, isNotFromMuchMuch } from '@/helpers/is-from-app';
import centerSlider from '@/components/mobile/slider/center-slider.vue';
import headerCart from '@/components/mobile/Header/header-cart.vue';

const components = {
  centerSlider,
  headerCart,
};

const props = {
  isMobile: Boolean,
  isLogin: Boolean,
  favoriteUrl: String,
};

export default {
  name: 'choice-empty',
  components,
  props,
  computed: {
    setUrl,
    online,
    isNotFromMuchMuch,
  },
  methods: {
    goBackFromHeader,
  },
};
</script>

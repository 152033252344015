<template>
  <a
    class="item image-box"
    :href="`${banner.url}${banner.anchor}`"
    :target="banner.action"
    @click="$emit('click', banner)">
    <img :src="banner.img" :alt="banner.alt" />
    <ul class="fn-sm">
      <li v-for="(text, textIndex) in banner.content" :key="textIndex">
        {{ text }}
      </li>
    </ul>
  </a>
</template>

<script>
const props = {
  banner: Object,
  index: Number,
};
export default {
  name: 'centerSliderItem',
  props,
};
</script>
